import { ThemeProvider } from '@emotion/react';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjsLocaleData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { AuthGuard } from '@brainysoft/react-nest-container';
import { EngymodStatus, enableWindowToggler } from '@brainysoft/ui-kit';
import { LoadingPage } from '@brainysoft/ui-layout';

import { bsAntdTheme } from '@./shared/lib/app/antd-bs-theme';
import '@./shared/lib/app/spa-i18next';
import { trpcClient } from '@./shared/lib/app/trpc';
import '@./shared/lib/toastify/toastify-bs-theme.css';
import { trpc } from '@./shared/trpc';

import { IS_DEVELOPMENT } from '../environments/environment';
import '../styles/app.css';
import { bsTheme } from '../styles/theme';
import { AppRouter } from './app-router';
import { LazyReactQueryDevTools } from './debug';
import { DemoLogin } from './demo-login';
import { isEngymodEnabled } from './engymod';
import { ForceCacheCleaner } from './force-cache-cleaner';
import { LangProvider } from './i18n/selector';
import { PasswordWatcher } from './password-watcher';
import {TimezoneProvider} from "@./shared/lib/timezone/timezone-provider";

dayjs.extend(relativeTime);
dayjs.extend(dayjsLocaleData);
dayjs.extend(weekday);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

enableWindowToggler();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60, // 1 hour
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

if (isEngymodEnabled() || IS_DEVELOPMENT) {
  window['queryClient'] = queryClient;
}

const sessionStoragePersister = createSyncStoragePersister({
  storage: window.sessionStorage,
});
persistQueryClient({
  queryClient,
  persister: sessionStoragePersister,
});

export function App() {
  return (
    <ThemeProvider theme={bsTheme}>
      <ConfigProvider theme={bsAntdTheme}>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme={'colored'}
          icon={false}
        />

        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <ForceCacheCleaner />
            <EngymodStatus />
            <Suspense fallback={<LoadingPage />}>
              <AuthGuard authExtraFooter={<DemoLogin />}>
                <Suspense fallback={<LoadingPage />}>
                  <PasswordWatcher />
                  <LangProvider>
                    <TimezoneProvider>
                      <AppRouter />
                    </TimezoneProvider>
                  </LangProvider>
                </Suspense>
              </AuthGuard>
              <LazyReactQueryDevTools />
            </Suspense>
          </QueryClientProvider>
        </trpc.Provider>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
