import {createContext, FC, PropsWithChildren} from "react";
import {useQuery} from "@tanstack/react-query";
import {v1Api} from "../api";
import {dayjsTz} from "./dayjs-timezone";


// дефолтная таймзона в laravel - "Europe/Moscow"
const DEFAULT_BACKEND_TIMEZONE = "Europe/Moscow";

const TimezoneContext = createContext<string>(DEFAULT_BACKEND_TIMEZONE);

export const TimezoneProvider: FC<PropsWithChildren> = ({children}) => {

  const timezoneSetting = useQuery(
    ['@spa', 'timezone'],
    () => v1Api.get<string>('timezone'),
    {
      cacheTime: 1000 * 60 * 30,
      staleTime: 1000 * 60 * 10,
    }
  );

  const timezone = timezoneSetting.data || DEFAULT_BACKEND_TIMEZONE;

  dayjsTz.setDefault(timezone);

  // console.log('UI Timezone: ', timezone);
  // console.log('UI Current Date/Time: ', dayjsTz().format('DD.MM.YYYY HH:mm:ss Z'));

  return (
    <TimezoneContext.Provider value={timezone}>
      {children}
    </TimezoneContext.Provider>
  )
}