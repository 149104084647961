import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { LayoutContext, LinkRendererProps, UserMenuType } from '@brainysoft/ui-layout';
import { LinkRenderer } from '@brainysoft/react-router-layout';
import { useClearCache } from '@brainysoft/react-nest-container';
import { useAppsMenu } from './menu/apps-menu';
import { LegacyTechInfo } from './tech-info-legacy';
import { getPlatform } from '../environments/dom-constants';
import { IS_DEVELOPMENT } from '../environments/environment';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const SharedLinkRenderer: FC<LinkRendererProps> = (props) => {
  const href = props.href as string;
  const isAbsolute: boolean = href.startsWith('http://') || href.startsWith('https://');

  if (isAbsolute) {
    const href = props.href as string;
    const isActive = false;
    const link = (
      <a className={props.className + (isActive ? ' bs-layout__link_active' : '')} href={`${href}`} title={props.title}>
        {props.children}
      </a>
    );

    // Чтобы визуально было видно какая ссылка ведет в обратно в старую часть
    if (IS_DEVELOPMENT && getPlatform() === 'spa') {
      return <div style={{ opacity: 0.3 }}>{link}</div>;
    }

    return link;
  }

  return (
    <LinkRenderer title={props.title} matchEnd={props.matchEnd} href={props.href} className={props.className}>
      {props.children}
    </LinkRenderer>
  );
};

function useLogout() {
  const clear = useClearCache(true, true);
  return useCallback(() => {
    clear();
    window.location.href = getPlatform() === 'spa' ? '/api/v2/auth/logout' : '/logout';
  }, [clear]);
}

export const LayoutContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('basic');
  const m = useAppsMenu();
  const logout = useLogout();
  const location = useLocation();
  // const section = location.pathname.substring(1).split('/')[0] ?? 'unknown';
  const section = location.pathname.split('/').filter((r) => r !== '')[0]; // main | admin | и тд

  const myAccount = (<span style={{ whiteSpace: 'nowrap' }}>{t('userMenu.myAccount')}</span>) as any;

  const userMenu = useMemo<UserMenuType>(
    // () => [{ type: 'button', onClick: logout, key: 'logout', text: 'Выйти' }],
    () => [
      { key: 'my-account', href: '/3/my-account', text: myAccount, type: 'link' },
      { key: 'logout', text: t('userMenu.logout'), type: 'button', onClick: () => logout() },
    ],
    [logout]
  );

  return (
    <LayoutContext.Provider
      value={{
        activeApp: section,
        appsNav: m.data || [],
        isAppsNavLoading: m.isLoading,
        LinkRenderer: SharedLinkRenderer,
        // techInfo: <TechInfo />,
        techInfo: <LegacyTechInfo />,
        userMenu: userMenu,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
