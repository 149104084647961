import dayjs, {Dayjs} from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const dayjsTz = dayjs.tz;

// dayjs понимает на входе дату в sql, но не понимает 'DD.MM.YYYY'
// пришлось вернуть парсинг из этого формата
// считаем что дата которую парсим - в таймзоне ui
export const dayjsFromFormatTz = (date: string, format: string) => {
  return dayjs.tz(dayjs(date, format))
}

export const dayjsUnixTz: (timestamp: number) => Dayjs = (timestamp) => {
  return dayjs.tz(dayjs.unix(timestamp))
}